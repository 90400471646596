import React, { useRef, useEffect } from 'react';
import { dispatch } from 'use-bus';

import HeaderBar from '../components/header';
import { trackExpand } from '../utils/googleAnalytics';

const SearchBar = (props: any) => {
    const searchInput: any = useRef(null);

    useEffect(() => {
        searchInput.current.focus();
        if (document.location.pathname.indexOf('/ga4-vs') !== -1) {
            document.querySelector("#ga4types")?.classList.add('hidden');
            document.querySelector("#ga4vsga4360types")?.classList.remove('hidden');
            document.querySelector("#dimension-checkbox")?.classList.add('hidden');
            document.querySelector("#metric-checkbox")?.classList.add('hidden');
            document.querySelector("label[for='dimension-checkbox']")?.classList.add('hidden');
            document.querySelector("label[for='metric-checkbox']")?.classList.add('hidden');
            document.querySelector("#ga4typesevents")?.classList.add('hidden');
        }
        if (document.location.pathname.indexOf('/ga4-events') !== -1) {
            document.querySelector("#ga4types")?.classList.add('hidden');
            document.querySelector("#ga4typesevents")?.classList.remove('hidden');
            document.querySelector("#dimension-checkbox")?.classList.add('hidden');
            document.querySelector("#metric-checkbox")?.classList.add('hidden');
            document.querySelector("label[for='dimension-checkbox']")?.classList.add('hidden');
            document.querySelector("label[for='metric-checkbox']")?.classList.add('hidden');
        }

    });

    const toogleButton = (text: string) => {
        document.querySelector("#ui_expand")?.classList.toggle('hidden');
        document.querySelector("#ui-collapse")?.classList.toggle('hidden');
    
        const textButton = document.querySelector('#text-button-expand');
        if (textButton) {
            textButton.textContent = text === 'Expand' ? 'Collapse' : 'Expand';
        }
    
        trackExpand(text.toLocaleLowerCase());
    }
    
    const expandCollapseButton = () => {
        const textButton = document.querySelector('#text-button-expand');
        const valueButton = textButton?.textContent || 'Expand';  // ✅ Si es null, usa 'Expand'
        
        dispatch('ui/expand');
        toogleButton(valueButton);
    }    

    return (
        <div>
            <HeaderBar />
            <div className="container p-3 mx-auto">
                <div className="flex flex-col lg:items-center lg:justify-center lg:flex-row lg:space-x-4">
                    <span className="hidden lg:block input-group-text flex items-center py-1.5 text-base font-normal text-gray-700 text-center whitespace-nowrap rounded" id="basic-addon2">
                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="w-4" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                            <path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path>
                        </svg>
                    </span>
                    <input ref={searchInput} id="search" className="h-10 mt-4 border-gray-200 rounded-md lg:mt-0 lg:w-96 focus:border-teal-500 focus:ring focus:ring-primary focus:ring-opacity-40" type="search" placeholder="Search" onChange={props.search} />
                    <select id="ga4types" name="filter" className="rounded-lg border-gray-200 mt-4 lg:mt-0 bg-white text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-green-700 focus:z-10 focus:ring-2 focus:ring-green-700 focus:text-green-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center" onChange={props.search2}>
                        <option>All Data</option>
                        <option value='"LOOKER STUDIO","✅"'>Looker Studio ✅</option>
                        <option value='"EXPLORATIONS","✅"'>Explorations ✅</option>
                        <option value='"API","✅"'>API ✅</option>
                        <option value='"AUDIENCES","✅"'>Audiences ✅</option>
                        <option value='"CUSTOMIZATION","✅"'>Customization ✅</option>
                        <option value='"FILTERS/COMPARISONS","✅"'>Filters/Comparisons ✅</option>
                        <option value='"SUBPROPERTY FILTERS","✅"'>SubProperty Filters ✅</option>
                        <option value='"REALTIME","✅"'>Realtime ✅</option>
                        <option value='"IS CALCULATED","✅"'>is Calculated ✅</option>
                        <option value='"BENCHMARKING","✅"'>Benchmarking ✅</option>
                        <option value='"ACCESS REPORT","✅"'>Access Report ✅</option>
                        <option value='"CUSTOM INSIGHT","✅"'>Custom Insight ✅</option>
                        <option value='"REPORTING PICKERS","✅"'>Reporting Pickers ✅</option>
                    </select>

                    <select id="ga4typesevents" name="filter" className="hidden rounded-lg border-gray-200 mt-4 lg:mt-0 bg-white text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-green-700 focus:z-10 focus:ring-2 focus:ring-green-700 focus:text-green-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center" onChange={props.search2}>
                        <option>All Data</option>
                        <option >Limits</option>
                        <option >Reserved</option>
                        <option >Recommended</option>
                        <option >Parameter</option>
                        <option >Item</option>
                        <option >Auto</option>
                    </select>

                    <select id="ga4vsga4360types" name="filter" className="hidden rounded-lg border-gray-200 mt-4 lg:mt-0 bg-white text-sm font-medium text-gray-900 hover:bg-gray-100 hover:text-green-700 focus:z-10 focus:ring-2 focus:ring-green-700 focus:text-green-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center" onChange={props.search2}>
                        <option>All Data</option><option>Account</option><option>Limits</option><option>Data Retention</option><option>Data Freshness</option><option>Data Import</option><option>Features</option><option>Product Links</option><option>Explorations</option><option>Customize</option><option>Data API</option><option>Admin Api</option>
                    </select>
                    <div className="flex flex-col mt-4 space-y-3 lg:mt-0 sm:flex-row sm:space-y-0 sm:space-x-3 sm:items-center sm:justify-center">
                        <div className="flex items-center mr-4">
                            <input defaultChecked id="dimension-checkbox" type="checkbox" value="dimension" onChange={props.filter} className="w-4 h-4 text-yellow-300 bg-gray-100 border-gray-300 rounded focus:ring-yellow-500 dark:focus:ring-yellow-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"></input>
                            <label htmlFor="dimension-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Dimensions</label>
                        </div>
                        <div className="flex items-center mr-4">
                            <input defaultChecked id="metric-checkbox" type="checkbox" value="metric" onChange={props.filter} className="w-4 h-4 text-purple-300 bg-gray-100 border-gray-300 rounded focus:ring-purple-500 dark:focus:ring-purple-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"></input>
                            <label htmlFor="metric-checkbox" className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">Metrics</label>
                        </div>
                        <button onClick={expandCollapseButton} className="rounded-lg border border-gray-200 bg-white text-sm font-medium px-4 py-2 text-gray-900 hover:bg-gray-100 hover:text-green-700 focus:z-10 focus:ring-2 focus:ring-green-700 focus:text-green-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 inline-flex items-center">
                            <svg id="ui_expand" className="w-4 h-4 mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 4a1 1 0 011-1h4a1 1 0 010 2H6.414l2.293 2.293a1 1 0 11-1.414 1.414L5 6.414V8a1 1 0 01-2 0V4zm9 1a1 1 0 010-2h4a1 1 0 011 1v4a1 1 0 01-2 0V6.414l-2.293 2.293a1 1 0 11-1.414-1.414L13.586 5H12zm-9 7a1 1 0 012 0v1.586l2.293-2.293a1 1 0 111.414 1.414L6.414 15H8a1 1 0 010 2H4a1 1 0 01-1-1v-4zm13-1a1 1 0 011 1v4a1 1 0 01-1 1h-4a1 1 0 010-2h1.586l-2.293-2.293a1 1 0 111.414-1.414L15 13.586V12a1 1 0 011-1z" clipRule="evenodd"></path></svg>
                            <svg id="ui-collapse" className="w-4 h-4 mr-1 hidden" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clipRule="evenodd"></path></svg>
                            <span id='text-button-expand'>Expand</span>
                        </button>
                        <button data-feedbackfin-button id="feedback" className="rounded-lg border border-red-600 bg-white text-sm font-medium px-4 py-2 text-red-900 hover:bg-red-100 hover:text-red-700 inline-flex items-center animate-pulse hidden">🤙 Feedback</button>

                    </div>
                </div>
            </div>
        </div>
    );
};

export default SearchBar;
