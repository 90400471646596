import React from 'react';
import { Switch, Route } from "react-router-dom";
import Home from './home';
import NoMatch from './no_match';
import Pages from './pages';



const App = () => (
    <Switch>
        <Route exact path="/">
            <Home />
        </Route>
        <Route exact path="/ga4-vs-ga4-360">
            <Pages /> 
        </Route>
        <Route exact path="/ga4-events-and-parameters">
            <Pages /> 
        </Route>
        <Route exact path="/ga4-events-parameters">
            <Pages /> 
        </Route>
        <Route path="*">
            <NoMatch />
        </Route>
    </Switch>
);

export default App;
