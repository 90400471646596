import React, { useState, useEffect } from 'react';

import Category from '../modules/models/category';
import Subcategory from '../modules/models/subcategory';

import { trackView, trackSearch } from '../utils/googleAnalytics';

import SearchBar from '../components/searchBar';
import CategoriesPages from '../components/categoriesPages';
import Footer from '../components/footer';

let timeoutSearch: any;
const urlParams = new URLSearchParams(window.location.search);
const info = urlParams.get('info');


const Pages = (props: any) => {
    let json: Category[] = require('../modules/ga4vsga4360.json');
    let isGA4360 = true;
    let description = 'Complete GA4 and GA4 360 cheatsheet comparing one by one all their limits, features, product integrations and API Quotas';
    let title = 'Google Analytics 4 vs Google Analytcs 4 360 (GA4 vs GA4 360)';
    let url = 'https://data.ga4spy.com/ga4-vs-ga4-360';
    let image = '/static/images/ga4_vs_ga4_360_cheatsheet.png';
    if (document.location.pathname.indexOf('ga4-events') !== -1 ) {
        json = require('../modules/ga4eventsparameters.json');
        isGA4360 = false;

        description = 'Access the handy GA4 events and parameters cheatsheet. Enhance your understanding  Google Analytics 4.';
        title = 'GA4 Events and Parameters Cheatsheet';
        url = 'https://data.ga4spy.com/ga4-events-parameters';
        image = '/static/images/ga4events-parameters-cheatsheet.png';
    }


    let filterParameter: Category[];
    const urlParams = new URLSearchParams(window.location.search);
    const type = urlParams.get('type');
    const searchTerm = urlParams.get('search');


   
    useEffect(() => {


        document.title = title;
        document.querySelector("meta[name='description']").setAttribute("content", description);
        document.querySelector("meta[property='og:url']").setAttribute('content', url);
        document.querySelector("meta[property='og:title']").setAttribute("content", title);
        document.querySelector("meta[property='og:description']").setAttribute("content", description);
        document.querySelector("meta[property='og:image']").setAttribute('content', image);

        document.querySelector("meta[property='twitter:url']").setAttribute('content', url);
        document.querySelector("meta[property='twitter:title']").setAttribute("content", title);
        document.querySelector("meta[property='twitter:description']").setAttribute("content", description);
        document.querySelector("meta[property='twitter:image']").setAttribute('content', image);

    }, []);


    if (searchTerm) {
        filterParameter = json.map(function (elements) {
            var result = elements.content.filter(function (items) {
                if (JSON.stringify(items).toLocaleUpperCase().indexOf(decodeURIComponent(searchTerm.toLocaleUpperCase())) > -1) {
                    return items
                }
            })
            if (result.length) {
                return {
                    title: elements.title,
                    content: result
                }
            }
        }).filter(Boolean);
    }
    if (type && type!=="all data") {
        filterParameter = json.map(function (elements) {
            var result = elements.content.filter(function (items) {
                debugger;
                if (items.type === decodeURIComponent(type.toLocaleUpperCase()) || items.title === decodeURIComponent(type.toLocaleLowerCase())) {
                    return items
                }
            })
            if (result.length) {
                return {
                    title: elements.title,
                    content: result
                }
            }
        }).filter(Boolean);
    }
    let result = filterParameter || json;
    const [cheatsheet, setCheatsheet] = useState<Category[]>(result);

    const search2 = (event: any) => {
        const text: string = event.target.value.toUpperCase();
        removeParameterURL('search');
        addParameterURL('type', text);
        const searchInput = document.querySelector("#search") as HTMLInputElement | null;
        if (searchInput) {
            searchInput.value = ''; // ✅ Ahora TypeScript reconoce `value`
        }
                let json: Category[] = require('../modules/ga4vsga4360.json');
        if (document.location.pathname.indexOf('ga4-events') !== -1) {
            json = require('../modules/ga4eventsparameters.json');
        }
        let filterParameter: Category[];
        if (text && text !== 'ALL DATA') {
            filterParameter = json.map(function (elements) {
                let result = elements.content.filter(function (items) {
                    if (items['type'].includes(text)) {
                        return items
                    }
                })
                if (result.length) {
                    return {
                        title: elements.title,
                        content: result
                    }
                }
            }).filter(Boolean);
        }
        let result = filterParameter || json;


        setCheatsheet(result);
        trackSearch(text);
    }
    const addParameterURL = (key: string, value: string) => {
        const url: any = new URL(window.location.href);
        url.searchParams.set(key, value.toLocaleLowerCase());
        window.history.pushState(null, '', url.toString());
    }
    const removeParameterURL = (key: string) => {
        const url: any = new URL(window.location.href);
        url.searchParams.delete(key);
        window.history.pushState(null, '', url.toString());
    }

    const search = (event: any) => {
        let json: Category[] = require('../modules/ga4vsga4360.json');
        if (document.location.pathname.indexOf('ga4-events') !== -1) {
            json = require('../modules/ga4eventsparameters.json');
        }   


        let text: string = event.target.value;
        text = text.toLocaleLowerCase();

        if (text === '') {
            removeParameterURL('search');
            removeParameterURL('type');
            setCheatsheet(json);
            return;
        }
        const selectElement = document.querySelector("#ga4vsga4360types") as HTMLSelectElement | null;
        if (selectElement) {
            selectElement.value = selectElement.options[0].value; // ✅ Acceder al primer `<option>`
        }
                addParameterURL('search', text);
        removeParameterURL('type');
        clearTimeout(timeoutSearch);

        timeoutSearch = setTimeout(() => {
            let filterParameter: Category[];
            filterParameter = json.map((category: Category) => {
                return {
                    ...category,
                    'content': category.content.map((subcategory: Subcategory) => {
                        return {
                            ...subcategory,
                            // Si el texto de búsqueda existe en el título o la descripción se muestra toda la tabla, si no pues se filtra en ella
                            'table': subcategory.title.toLocaleLowerCase().includes(text) || subcategory.description.toLocaleLowerCase().includes(text) ? subcategory.table : subcategory.table.filter(tr => {
                                let isValid = false;
                                tr.forEach(td => {
                                    if (!isValid) {
                                        isValid = td.toLocaleLowerCase().includes(text);
                                    }
                                });

                                return isValid;
                            }),
                        };
                    }),
                };
            });

            let result = filterParameter || json;
            setCheatsheet(result);
            trackSearch(text);
        }, 500);
    };
    return (
        <>
            <SearchBar search={search} search2={search2} />
            <CategoriesPages cheatsheet={cheatsheet} />
            <Footer />
        </>
    );
}

export default Pages;
